import { Box, chakra, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Section } from 'common/section'
import { addCdn } from 'utils/env'

export const Features = () => {
  return (
    <Section textAlign={'center'}>
      <Text textStyle={'subtitle'}>
        Crescer tem que ser{' '}
        <chakra.span color={'primary'}> simples</chakra.span>
      </Text>
      <Text>
        Decolar uma empresa exige trabalho e dedicação, mas
        pode ser muito simples. Para nós, toda empresa tem três fases bem
        distintas. Nosso trabalho é fazer sua empresa passar por elas.
      </Text>
      <SimpleGrid textAlign={'left'} columns={[1, 3]} spacing={12}>
        <VStack
          borderRadius={'md'}
          border={'1px solid'}
          borderColor={'gray.200'}
          align={'center'}
          spacing={1}
        >
          <Image
            m={10}
            alt={'Clareza'}
            src={addCdn('/images/illustrations/clarity.svg')}
            maxW={'sm'}
            loading={'lazy'}
          />
          <Text textStyle={'h1'} fontWeight={900} color={'primary'}>
            1 - Clareza
          </Text>
          <Box h={3} />
          <Text p={[4, 6]}>
            A prioridade aqui é estabilidade, clareza financeira e encontrar o
            balanço ideal entre gastos e vendas.
          </Text>
        </VStack>
        <VStack
          align={'center'}
          borderRadius={'md'}
          border={'1px solid'}
          borderColor={'gray.200'}
          spacing={1}
        >
          <Image
            m={10}
            alt={'Crescimento'}
            src={addCdn('/images/illustrations/b3_growth.svg')}
            maxW={'sm'}
            loading={'lazy'}
          />
          <Text textStyle={'h1'} fontWeight={900} color={'primary'}>
            2 - Crescimento
          </Text>
          <Box h={3} />
          <Text p={[4, 6]}>
            Hora de fazer dinheiro! Agora é abrir novos canais de vendas,
            melhorar o marketing e correr atrás do crescimento!
          </Text>
        </VStack>
        <VStack
          align={'center'}
          borderRadius={'md'}
          border={'1px solid'}
          borderColor={'gray.200'}
          spacing={1}
        >
          <Image
            m={10}
            alt={'Consolidação'}
            src={addCdn('/images/illustrations/consolidation.svg')}
            maxW={'sm'}
            loading={'lazy'}
          />
          <Text textStyle={'h1'} fontWeight={900} color={'primary'}>
            3 - Consolidação
          </Text>
          <Box h={3} />
          <Text p={[4, 6]}>
            Organização de processos e delegação são as palavras de ordem. A
            empresa vai ter que funcionar com pouco envolvimento do
            proprietário.
          </Text>
        </VStack>
      </SimpleGrid>
    </Section>
  )
}
