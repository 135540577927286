import { Button, chakra, HStack, Image, Text } from '@chakra-ui/react'
import { addCdn } from 'utils/env'
import { Section } from '../common/section'



export const HomeCallToAction = () => {
 

  return (
    <>
      <Section textAlign={'center'}>
        <Image
          alignSelf={'center'}
          alt={'motivation'}
          src={addCdn('/images/illustrations/idea.svg')}
          maxW={'sm'}
          loading={'lazy'}
        />
        <Text textStyle={'title'} textAlign={'center'}>
          O que te <chakra.span color={'primary'}>motivou</chakra.span> a criar
          sua empresa?
        </Text>
        <Text>
          Nós encontramos nosso propósito e não conseguimos mais viver sem ele.{' '}
          <br />
          Compartilhe o seu com a gente ou vamos criá-lo juntos.
        </Text>
        <HStack w={'full'} justify={'center'} px={2}>
          <Button bg={'primary'} py={4} px={6} size={'xl'} >
            <a href="https://berry.tech">
             Quero decolar minha empresa
            </a>
          </Button>
        </HStack>
      </Section>
    </>
  )
}
