import { Box, Button, Flex, HStack, StackProps } from '@chakra-ui/react'
import { SiteNavigationItems } from 'layout/navigation'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { openDrawerMutation } from 'stores/layout'
import { PullstateCore } from 'stores/root'

interface Props extends StackProps {}

export const Appbar: FC<Props> = () => {
  const { Store } = PullstateCore.useStores()
  const router = useRouter()

  return (
    <Flex id={'appbar'} justify={'center'} w={'full'} py={2}>
      <HStack
        id={'header'}
        h={'64px'}
        px={2}
        w={'full'}
        bg={'white'}
        justify={'space-between'}
        maxW={'container.xl'}
      >
        <Box cursor={'pointer'} py={2}>
          <Image
            loading={'lazy'}
            height={46}
            width={167}
            alt={'Berrytech Logo'}
            src={'/images/logos/logo_berrytech.svg'}
            onClick={() => router.push('/')}
          />
        </Box>
        <SiteNavigationItems />
        <Flex flex={1} />
        <Button
          size={'sm'}
          onClick={() => Store.update(openDrawerMutation)}
          variant={'icon'}
        >
          <GiHamburgerMenu size={22} />
        </Button>
      </HStack>
    </Flex>
  )
}
