import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react'
import { CallToAction } from 'common/call_to_action'
import { Section } from 'common/section'
import { addCdn } from 'utils/env'



export const HomeHero = () => {
  

  return (
    <Section>
      <Stack direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={10}>
          <Text textStyle={'title'}>
            <Text as={'span'}>Nós</Text>
            <br />
            <Text as={'span'} color='primary'>
              decolamos
            </Text>
            <br />
            <Text as={'span'}>empresas</Text>
          </Text>
          <Text>
            A Berrytech é uma consultoria acessível, inovadora, 100% online e
            que já turbinou mais de 1000 empresas de diversos segmentos em 27
            estados do Brasil.
          </Text>
          <CallToAction  />
          <Box h={2} />
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
        >
          <Box w={'full'} h={'full'}>
            <Image
              objectFit={'cover'}
              w={'full'}
              alt={'Illustração Berrytech'}
              src={addCdn('/images/illustrations/release.svg')}
            />
          </Box>
        </Flex>
      </Stack>
    </Section>
  )
}
