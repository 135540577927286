import { Box, chakra, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Section } from 'common/section'
import { FC } from 'react'
import { BsCheck } from 'react-icons/bs'

interface FeatureProps {
  heading: string
  text: string
}

interface Props {
  onOpenForm?: () => void
}

export const Benefits: FC<Props> = () => {
  return (
    <Section bg={'backgroundAlt'}>
      <VStack spacing={10} align={'flex-start'}>
        <Text textStyle={'overline'}>Benefícios</Text>
        <Text textStyle='subtitle'>
          O que podemos <chakra.span color={'primary'}>melhorar</chakra.span> na
          sua empresa
        </Text>
        <Box h={4} />
        <SimpleGrid columns={[1, 2]} spacing={10}>
          <Feature
            heading={'Mais resiliência'}
            text={
              'Esperar pelo governo ou a economia melhorar não é estratégia. Você vai aprender a gerar riqueza mesmo em crises e instabilidade.'
            }
          />
          <Feature
            heading={'Mais segurança'}
            text={
              'Sentir-se no controle da empresa mesmo em momentos de instabilidade é algo valioso.'
            }
          />
          <Feature
            heading={'Melhor fluxo de caixa'}
            text={
              'Tornar os números confiáveis possibilitam uma boa gestão financeira e, assim, operar com caixa sobrando sem passar apertos.'
            }
          />
          <Feature
            heading={'Mais lucro'}
            text={
              'Chega de não ter dinheiro no final do mês, controle financeiro, mais vendas e melhor marketing, transformam qualquer realidade, vamos te explicar certinho o que fazer.'
            }
          />
          <Feature
            heading={'Maior valor de mercado'}
            text={
              'Uma empresa que cresce constantemente e de forma sólida aumenta seu valor de mercado.'
            }
          />
          <Feature
            heading={'Mais velocidade'}
            text={
              'Você pode aprender tudo que precisa sozinho? Com certeza! Só que estamos aqui para fazer acontecer em uma fração do tempo.'
            }
          />
        </SimpleGrid>
      </VStack>
    </Section>
  )
}

export const Feature = ({ heading, text }: FeatureProps) => {
  return (
    <VStack w={'full'} align={'flex-start'}>
      <HStack spacing={4}>
        <Box color={'accent'}>
          <BsCheck size={30} />
        </Box>
        <Text textStyle={'h1'}>{heading}</Text>
      </HStack>
      <Text>{text}</Text>
    </VStack>
  )
}
