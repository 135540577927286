import { VStack } from '@chakra-ui/react'
import { serverSidePropsWithAuth } from 'auth/components/context'
import Footer from 'common/footer'
import { Institutional } from 'common/institutional'
import { FormModal } from 'form/components/form_modal'
import { AboutUs } from 'home/about'
import { Benefits } from 'home/benefits'
import { testimonialCases } from 'home/case'
import { HomeCallToAction } from 'home/cta'
import { Features } from 'home/features'
import { HomeHero } from 'home/hero'
import { Testimonials } from 'home/testimonials'
import { Appbar } from 'layout/appbar'
import Head from 'next/head'

export const getServerSideProps = serverSidePropsWithAuth

const Home = () => {
  return (
    <VStack w='full'>
      <Head>
        <title>Berrytech - Nós decolamos empresas</title>
      </Head>
      <Appbar />
      <HomeHero  />
      <Features />
      <AboutUs />
      <Testimonials testimonials={testimonialCases} />
      <Benefits  />
      <HomeCallToAction  />
      <Institutional />
      <Footer />
      <FormModal />
    </VStack>
  )
}

export default Home
